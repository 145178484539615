import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { Message } from 'element-ui';
// Vue.use(axios)
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$message = Message


Vue.directive('noMoreClick', {
  inserted(el, binding) {
    el.addEventListener('click', e => {
      el.classList.add('is-disabled')
      el.disabled = true
      setTimeout(() => {
        el.disabled = false
        el.classList.remove('is-disabled')
      }, 15000)
    })
  }
})

new Vue({
  render: h => h(App),
}).$mount('#app')
