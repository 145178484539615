<template>
  <div id="app">
    <template v-if="!isSearch">
      <template v-if="serial_num">
        <div>
          <el-button type="success" @click.stop="copyLink(serial_num, '下载码')"
            >分享下载码</el-button
          >
          <div class="serial_num">{{ serial_num }}</div>
        </div>
      </template>
      <el-upload
        class="upload-demo"
        ref="upload"
        action="#"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :on-change="changeFile"
        :http-request="beforeUpload"
        :before-remove="beforeRemove"
        :multiple="false"
        :on-exceed="handleExceed"
        :file-list="fileList"
        accept=".dump,.bin"
      >
        <el-button v-no-more-click type="primary" class="choose_file"
          >选择文件</el-button
        >
        <div slot="tip" class="el-upload__tip">
          请上传1KB的.dump或.bin文件！
        </div>

        <el-button
          type="success"
          class="copyLink"
          @click.stop="copyLink(`${link}/${file_id}`, '链接')"
          >复制链接</el-button
        >
        <el-button type="success" @click.stop="toggle">搜索下载码</el-button>
      </el-upload>
    </template>
    <template v-if="isSearch">
      <div class="search_box">
        <el-input v-model="serial_input" placeholder="请输入下载码"></el-input>
        <el-button type="success" @click.stop="serialSearch">下载</el-button>
        <el-button @click.stop="toggle">返回</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "App",
  components: {},
  data() {
    return {
      isSearch: false,
      file_id: "",
      serial_num: "",
      serial_input:'',
      link: "http://share.ickashuju.com/api/download",
      // link: "http://127.0.0.1:8010/api/download",
      dialogVisible: false,
      fileList: [],
    };
  },
  methods: {
    download(){
      if(this.file_id){
        let a_dom = document.createElement("a")
        a_dom.setAttribute("download","")
        a_dom.setAttribute("targer","_self")
        a_dom.href = `${this.link}/${this.file_id}`
        a_dom.click()
      }
    },
    serialSearch(){
      axios({
        method: "post",
        url: `/api/search/${this.serial_input}`
      }).then((res) => {
        this.file_id = res.data.data.file_id;
        this.download()
      });
    },
    toggle() {
      this.isSearch = !this.isSearch;
    },
    copyLink(str, tips) {
      if (!this.file_id) {
        this.$message.error("请先上传文件！");
        return;
      }
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(`${str}`).then(() => {
          this.$confirm(`${tips}复制成功啦，快去发送给你的好友吧~`, {
            showClose: false,
            showCancelButton: false,
            confirmButtonText: "确定",
            type: "success",
            callback: function () {},
          });
        });
      } else {
        // 创建text area
        let textArea = document.createElement("textarea");
        textArea.value = `${str}`;
        // 使text area不在viewport，同时设置不可见
        textArea.style.position = "absolute";
        textArea.style.opacity = 0;
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // 执行复制命令并移除文本框
          document.execCommand("copy") ? res() : rej();
          textArea.remove();
          this.$confirm(`${tips}复制成功啦，快去发送给你的好友吧~`, {
            showClose: false,
            showCancelButton: false,
            confirmButtonText: "确定",
            type: "success",
            callback: function () {},
          });
        });
      }
    },
    beforeUpload(file) {
      let files = file.file;
      if (files.size != 1024) {
        this.$refs.upload.clearFiles();
        this.$message.error("请上传1KB的.dump或.bin文件！");
        return;
      }
      let fd = new FormData();
      fd.append("file", files);
      axios({
        method: "post",
        url: "/api/upload",
        data: fd,
      }).then((res) => {
        console.log("res--->", res);
        this.file_id = res.data.data.file_id;
        this.serial_num = res.data.data.serial_num;
      });
    },
    changeFile(file, fileList) {
      if (fileList.length > 1) {
        fileList.shift();
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
  },
};
</script>

<style>
#app {
  margin-top: calc(30vh);
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.upload-demo {
  display: inline-block;
}
.search_box{
  /* display: flex; */
  column-gap: 10px;
  
}
.search_box .el-input{
  width: auto;
  margin-right: 10px;
}
.el-button.copyLink.el-button--success {
  /* margin-left: 90px; */
}
.el-message-box__wrapper .el-message-box__btns {
  text-align: center;
  border-top: 1px solid #f2f2f2;
}
.el-message-box__wrapper
  .el-message-box__btns
  .el-button.el-button--default.el-button--small.el-button--primary {
  color: #e49c18;
  background-color: unset;
  border: unset;
}
.serial_num {
  margin: 20px 0;
  color: red;
  font-size: 20px;
  font-weight: 700;
}
@media screen and (max-width: 750px) {
  .el-message-box {
    margin-top: calc(100vh / 2);
    translate: 0 -50%;
  }
}
</style>
